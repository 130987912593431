.Stats {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  background-color: #3ec1c9;
  color: white;
  margin-top: 0px;

  &__column {
    display: flex;
  }
}

@media screen and (min-width: 480px) {
  .Home__lead_text {
    font-size: 4rem;
    padding: 12px;
  }
}
