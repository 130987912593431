.Footer {
  width: 100vw;
  color: white;
  background-color: #32486C;
  padding: 4rem;
}

.FooterLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 2rem;
}

.FooterLink a {
  color: white;
  text-decoration: none;
}
