.Hero {
    background: url(../../assets/img/hero.jpeg) no-repeat center center;
    background-size: cover;
    height: 600px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 0;
}
.Hero__overlay {
  height: 600px;
  width: 100vw;
  display: flex;
  align-items: center;
}
.Hero__title {
  font-size: 6rem;
  font-weight: 200;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
}

@media screen and (max-width: 480px){
  .Hero__title {
    font-size: 2.5rem;
  }
}
