.CoverImage {
  height: 300px;
  background-color: #f5f5f5;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  color: white;
  background-image: url('../../assets/img/about-banner.jpeg');

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}
