:root {
  --bs-body-font-family: 'Montserrat', sans-serif;
  --bs-primary: #3EC1C9;
  --bs-btn-bg: #3EC1C9;
}

* {
  box-sizing: border-box;

}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Montserrat', sans-serif !important;
}

.TopNavbar {
  height: 40px;
  width: 100vw;
  background-color: #32486C;
}

.navbar .navbar-light {
  background-color: white;
}

.btn-primary {
  background-color: #3EC1C9;
  border-color: #3EC1C9;
}
