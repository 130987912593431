.Home {
  &__stories {
    display: flex;
    flex-direction: column;
    padding: 12px;

    img {
      border-radius: 4px;
      margin-bottom: 8px;
    }

    p {
      min-height: 160px;
    }

    div {
      position: relative;

      button {
        position: absolute;
        bottom: 0;
      }
    }
  }

  &__tabs {
    border: 2px solid #3ec1c9;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }

  &__tabs:hover {
    border-radius: 4px;
    background-color: #3ec1c9;
    color: white;
  }

  &__stats {
    display: flex;
    flex-direction: row;

    height: 200px;
    width: 100%;
    background-color: #3ec1c9;
    color: white;
    margin-top: -80px;

    &__column {
      width: 30%;
      display: flex;
    }
  }
}

.Home__industries {
  display: flex;
  align-items: center;
  background-color: #3ec1c9;
  color: white;
  text-align: center;
  padding: 1.5rem;
  margin-top: -4rem;
  z-index: 9999999;
}

.Home__industries__icons {
  width: 56px;
  height: 56px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Home__industry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 480px) {
  .Home__lead_text {
    font-size: 4rem;
    padding: 12px;
  }
}
